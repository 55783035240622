// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("@nathanvda/cocoon")
require("dropzone")
require("lightslider")
require('bootstrap-star-rating')
require("trix")
require("@rails/actiontext")
require('talkjs')

import Dropzone from 'dropzone'
import $ from 'jquery';
import 'jquery-ui';
import 'jquery-ui/ui/widgets/datepicker';

import swal from 'sweetalert';

import 'dropzone/dist/dropzone.css'
import 'bootstrap-star-rating/css/star-rating.min.css'

import './select2'
import './popover'
import './profile_details'
import './service_details'
import './products_n_services'
import './messaging'
import './video_call'
import './certificates_n_awards'
import './sign_in'
import './sign_up'
import './reset_password'
import './new_password'
import './contact_us'
import './deal'
import './products/index'
import './supplier/live_deals'
import './my_company/show'
import './companies/show'
import './reviews/index'
import './posts'
import './invite_form'
import './credit_cards/create'
import './account_setting'
import './admin/index'
import './admin/matching'
import './admin/smart_matching'
import './admin/companies'
import './welcome/landing_page'
import './welcome/resend_email_verification'
import './device_warning'
import './live_search'
import './app_notifications'
import './connections'
import './matches'
import './personal_detail'
import './company_detail'
import './tagify'
import './growsurf'
import './connection_recommendation'
import './admin/buyer/add_buyer'
import './admin/companies/add_member'
import './user/edit_member'
import './user/add_teams'
import './user/create_user'
import './team/create_team'
import './team/edit_team'
import './team/team'
import './catalog'
import './conversations'
import './chat'
import './supply_tasks/create_supply_task_requests'
import './deal_sample_request/update_deal_sample_request'
import './deal_information_request/create_deal_information_request'
import './deal_information_request/update_deal_information_request'
import './deal_pricing_request/create_deal_pricing_request'
import './deal_pricing_request/update_deal_pricing_request'
import './sectors_n_tags'

Dropzone.autoDiscover = false;

window.swal = swal;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


$(() => {
    // Auto dismiss message after 10 seconds
    setTimeout(() => {
        $("#flash-wrapper").slideUp(500);
    }, 10000);

    $('.nav-item.dropdown.account-detail-nav').hover(function () {
        $(this).addClass('show');
        $(this).find('.dropdown-menu').addClass('show');
        $(this).find('#navbarDropdownMenuLink').attr('aria-expanded', true);
    }, function () {
        $(this).removeClass('show');
        $(this).find('.dropdown-menu').removeClass('show');
        $(this).find('#navbarDropdownMenuLink').attr('aria-expanded', false);
    });
});

window.FlashNotice = {
    show() {
        $("#flash-wrapper").show();
        setTimeout(() => {
            $("#flash-wrapper").slideUp(500);
        }, 10000)
    }
}

$('.left-sidebar-toggle').on('click', function () {
    $('.left-sidebar-wrapper').toggleClass('closed');
    $('.page-content-wrapper').toggleClass('closed-left');
    $('.page-content-wrapper').toggleClass('open-left');
});

$('.right-sidebar-toggle').on('click', function () {
    $('.right-sidebar-wrapper').toggleClass('closed');
    $('.page-content-wrapper').toggleClass('closed-right');
    $('.page-content-wrapper').toggleClass('open-right');
});

const leftSidebarWrapper = document.querySelector('.left-sidebar-wrapper');
const leftSidebarIcon = document.querySelector('#left-sidebar-icon');
const leftSidebarTitle = document.querySelector('.left-sidebar-title');
const leftSidebarContent = document.querySelector('.left-sidebar-content');

const rightSidebarWrapper = document.querySelector('.right-sidebar-wrapper');
const rightSidebarIcon = document.querySelector('#right-sidebar-icon');
const rightSidebarTitle = document.querySelector('.right-sidebar-title');
const rightSidebarContent = document.querySelector('.right-sidebar-content');

document.addEventListener('DOMContentLoaded', function () {
    var favicon = document.getElementById('favicon');
    var darkModeFaviconPath = favicon.dataset.darkModeFavicon;
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        favicon.href = darkModeFaviconPath;
    }

    const pageContentWrapper = document.querySelector('.page-content-wrapper');
    if (pageContentWrapper) {
        pageContentWrapper.classList.add('ready');
    }

    if (leftSidebarWrapper) {
        $('.page-content-wrapper').toggleClass('open-left');
    } else {
        $('.page-content-wrapper').toggleClass('closed-left');
    }

    if (rightSidebarWrapper) {
        $('.page-content-wrapper').toggleClass('open-right');
    } else {
        $('.page-content-wrapper').toggleClass('closed-right');
    }
});

if (leftSidebarWrapper) {
    leftSidebarWrapper.addEventListener('transitionend', function (event) {
        const isClosed = leftSidebarWrapper.classList.contains('closed');
        leftSidebarIcon.style.transform = isClosed ? 'rotate(180deg)' : 'rotate(0deg)';
        leftSidebarContent.style.display = isClosed ? 'none' : 'block';
        // leftSidebarTitle.style.transform = isClosed ? 'translateX(85%) rotate(90deg) translateY(20%)' : 'rotate(0deg)';
    });
}

if (rightSidebarWrapper) {
    rightSidebarWrapper.addEventListener('transitionend', function (event) {
        const isClosed = rightSidebarWrapper.classList.contains('closed');
        rightSidebarIcon.style.transform = isClosed ? 'rotate(180deg)' : 'rotate(0deg)';
        rightSidebarContent.style.display = isClosed ? 'none' : 'block';
        // rightSidebarTitle.style.transform = isClosed ? 'rotate(-90deg) translateX(-85%) translateY(-20%)' : 'rotate(0deg)';
    });
}

$(function () {
    if (window.innerWidth <= 991 && !$('.left-sidebar-wrapper').hasClass('closed')) {
        $('.left-sidebar-wrapper').toggleClass('closed');
        $('.page-content-wrapper').toggleClass('closed-left');
    }
});

$(function () {
    if (window.innerWidth <= 991 && !$('.right-sidebar-wrapper').hasClass('closed')) {
        $('.right-sidebar-wrapper').toggleClass('closed');
        $('.page-content-wrapper').toggleClass('closed-right');
    }
});

$(window).on("resize", function () {
    if (window.innerWidth <= 991 && !$('.left-sidebar-wrapper').hasClass('closed')) {
        $('.left-sidebar-wrapper').toggleClass('closed');
        $('.page-content-wrapper').toggleClass('closed-left');
    } else if (window.innerWidth > 991 && $('.right-sidebar-wrapper').hasClass('closed')) {
        $('.left-sidebar-wrapper').toggleClass('closed');
        $('.page-content-wrapper').toggleClass('closed-left');
    }

    if (window.innerWidth <= 991 && !$('.right-sidebar-wrapper').hasClass('closed')) {
        $('.right-sidebar-wrapper').toggleClass('closed');
        $('.page-content-wrapper').toggleClass('closed-right');
    } else if (window.innerWidth > 991 && $('.right-sidebar-wrapper').hasClass('closed')) {
        $('.right-sidebar-wrapper').toggleClass('closed');
        $('.page-content-wrapper').toggleClass('closed-right');
    }
});
